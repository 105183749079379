<template>
  <div class="d-flex flex-column align-items-center gap-24 h-100">
    <AppButton
      v-if="!isEditing"
      class="me-auto"
      :variant="BtnVariant.Tertiary"
      is-icon
      title="Закрыть"
      @click="$emit('close')"
    >
      <svg class="h-100 w-100">
        <use xlink:href="/public/icons/main.svg#close" />
      </svg>
    </AppButton>
    <label class="w-100">
      Дата и время
      <DatetimePicker
        v-model="startTime"
      />
    </label>
    <div>
      <AppButton
        class="me-24 fw-bold"
        :variant="BtnVariant.Tertiary"
        :active="platform === 1"
        @click="changePlatform(1)"
      >
        Онлайн
      </AppButton>
      <AppButton
        class="fw-bold"
        :variant="BtnVariant.Tertiary"
        :active="platform === 0"
        @click="changePlatform(0)"
      >
        В офис
      </AppButton>
    </div>
    <label class="w-100">
      {{ platform === 0 ? 'Адрес' : 'Площадка' }}
      <input
        v-show="platform === 0"
        v-model="location"
        type="text"
        class="form-control"
      >
      <SelectLayout
        v-show="platform === 1"
        v-model="location"
        class="form-control"
        :options="platformList"
        :clearable="false"
      />
    </label>
    <label class="w-100">
      Детали
      <textarea
        v-model="description"
        class="form-control"
      />
    </label>
    <AppButton
      class="fw-bold"
      @click="invite()"
    >
      {{ isEditing ? 'Сохранить' : 'Пригласить' }}
    </AppButton>
  </div>
</template>

<script setup lang="ts">
import DatetimePicker from "~/components/inputs/datetimePicker.vue";
import { BtnVariant } from "~/composables/enums/BtnVariant";
import SelectLayout from "~/components/inputs/selectLayout.vue";
import type { PropType } from "vue";
import { onlineLocationList } from "~/composables/OnlineLocationList";

const { $toast } = useNuxtApp();

const emits = defineEmits(['invited', 'saved', 'close']);

const props = defineProps({
  vacancyId: { type: String, required: true },
  responseId: { type: String, required: true },
  activeResponse: { type: Object as PropType<any>, default: () => ({}) },
  isEditing: { type: Boolean, default: false },
});

//-----VARIABLES-----\\
const platformList = onlineLocationList;
let defaultDate = new Date();
defaultDate.setDate(defaultDate.getDate() + 1);
defaultDate.setMinutes(0);

//-----STATE-----\\
const startTime = ref<Date>(props.isEditing ? new Date(props.activeResponse.colloquy.startTime * 1000) : defaultDate);
const platform = ref<number>(props.isEditing ? props.activeResponse.colloquy.platform : 1);
const description = ref<string>(props.isEditing ? props.activeResponse.colloquy.description : null);
// const location = ref<string>(props.isEditing ? props.activeResponse.colloquy.location : null);
const location = ref<string>(props.isEditing ? props.activeResponse.colloquy.location : platformList[0]);
const isChangeDate = ref<boolean>(false);

watch(() => startTime.value, (o,n) => {
  if ((o.getFullYear() + o.getMonth() + o.getDay()) !== (n.getFullYear() + n.getMonth() + n.getDay())) {
    isChangeDate.value = true;
  }
});

//-----COMPUTED-----\\

//-----METHODS-----\\
async function invite() {
  if (startTime.value === undefined || platform.value === undefined) {
    return;
  }

  if (props.isEditing) {
    mainFetch(`/colloquies/${props.activeResponse.colloquy?.id}`, {
      method: 'PUT',
      body: {
        startTime: Math.round(startTime.value!.getTime() / 1000 - 0.5),
        platform: platform.value,
        description: description.value,
        location: location.value,
      }
    }).then((response) => {
      response = response.data.value??response.error?.value?.data;
      if (response.error.code !== 201) {
        $toast.error(response.error.clientMessage ?? response.error.message);
        return;
      }

      emits('saved', {
        isChangeDate: isChangeDate.value,
        newDate: new Date(startTime.value.getFullYear(), startTime.value.getMonth(), startTime.value.getDate())
      });
    });
  } else {
    mainFetch(`/vacancies/${props.vacancyId}/colloquies`, {
      method: 'POST',
      body: {
        response: props.responseId,
        startTime: Math.round(startTime.value!.getTime() / 1000 - 0.5),
        platform: platform.value,
        description: description.value,
        location: location.value
      }
    }).then((response) => {
      response = response.data.value??response.error?.value?.data;
      if (response.error.code !== 201) {
        $toast.error(response.error.clientMessage ?? response.error.message);
        return;
      }
      emits('invited', {responseId: props.responseId, platform: platform.value});
    });
  }
}
function changePlatform(newPlatform: 0|1) {
  if (platform.value === newPlatform) {
    return;
  }

  platform.value = newPlatform;
  if (newPlatform === 1) {
    location.value = platformList[0];
  } else {
    location.value = '';
  }
}
</script>