<template>
  <VueFinalModal
    content-class="modal-dialog modal-dialog-centered modal-md"
    display-directive="show"
  >
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading-section-4 fw-normal modal-title fs-5">
          Редактировать собеседование
        </p>
        <button
          class="btn-close"
          type="button"
          aria-label="Закрыть"
          title="Закрыть"
          @click="emits('confirm')"
        />
      </div>
      <div class="modal-body">
        <InviteToInterview
          class="p-10"
          :vacancy-id="vacancyId"
          :response-id="response.id"
          :active-response="response"
          is-editing
          @saved="(val) => emits('saved', val)"
        />
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import InviteToInterview from "~/components/company/InviteToInterview.vue";
import { VueFinalModal } from "vue-final-modal";
import type { Response } from "~/entities/response";
import type { PropType } from "vue";

defineProps({
  response: { type: Object as PropType<Response>, default: null },
  vacancyId: { type: Number, default: null }
});

const emits = defineEmits(['saved', 'confirm'])

</script>

<style scoped lang="scss">

</style>