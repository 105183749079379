<template>
  <div class="row">
    <div class="col-12 col-md-8">
      <div v-if="activeTab === 0" class="h2">Откликнулось на вакансию</div>
      <div v-else-if="activeTab === 1" class="h2">Приглашённые на доп. вопросы</div>
      <div v-else-if="activeTab === 2" class="h2">{{ isPastColloquies ? 'Прошедшие' : 'Назначенные' }} СОБЕСы</div>
      <div v-else-if="activeTab === 3" class="h2">Перемещены в архив</div>
      <CalendarColloquies
        v-if="activeTab === 2"
        is-company
        :busy-days="busyDays"
        :active-index="activeIndex"
        @change-tab="changeTab"
        @active-date="changeDate"
        @ungrouping-colloquies="ungroupingColloquies"
      />
      <div class="d-flex justify-content-between align-items-center py-12 mb-15">
        <SearchCounter
          :per-page="perPage"
          :count="itemsCount"
          :page="page"
        />
        <SelectLayout
          v-model="sorting"
          class="form-control-sorting bg-md-transparent bg-white shadow-md-none btn custom-w"
          :options="[
            {
              id: props.activeTab === 2 ? 'start_time:DESC' : 'created:DESC',
              title: 'Сначала новые',
            },
            {
              id: props.activeTab === 2 ? 'start_time:ASC' : 'created:ASC',
              title: 'Сначала старые',
            }
          ]"
          :reduce="(val) => val.id"
          :is-sorting="true"
          :searchable="false"
          :clearable="false"
          @update:model-value="sortResponses"
        />
      </div>
      <div
        v-show="responses.length === 0 && activeTab !== 3 && !loading"
        class="text-gray text-center mt-43"
      >
        <span v-if="activeTab === 0">
          На вакансию ещё никто не откликнулся. <span class="text-primary">Ожидайте или найдите</span> кандидатов сами в поиске.
        </span>
        <span v-else-if="activeTab === 1">
          Нет кандидатов на видеоинтервью. <span class="text-primary">Пригласите</span> соискателей из поиска.
        </span>
        <span v-else-if="activeTab === 2">
          Нет кандидатов на собеседование. <span class="text-primary">Пригласите</span> соискателей из поиска.
        </span>
      </div>
      <AppLoader
        :is-hide="!loading"
        class="w-100"
      />
      <div v-show="!loading">
        <ResponseCard
          v-for="(response, index) in getResponseItems"
          :id="response.id"
          :title="response.summary.client.name + ' ' + response.summary.client.surname"
          :is-set-interviewer="response.interviewer === null"
          :employee-id="client.id"
          :company-id="route.params.id??null"
          :date="new Date(response.created * 1000)"
          :status="getStatus(index)"
          :is-online="isOnline(index)"
          :img="response.summary.client.photo !== undefined ? response.summary.client.photo : null"
          :colloquy="response.colloquy?.platform"
          :start-time="response.colloquy === undefined ? undefined : new Date(response.colloquy.startTime * 1000)"
          :start-date="response.colloquy !== undefined ? response.colloquy.startDate : undefined"
          :platform="response.colloquy !== undefined ? response.colloquy.platform : undefined"
          :description="response.colloquy !== undefined ? response.colloquy.description : undefined"
          :show-view-status="activeTab === 0 && !response.isChangeStatus"
          :view-status="response.status === 1"
          :show-comment="true"
          :location="response.location"
          :is-active="activeResponse === index"
          :active-tab="activeTab"
          @click="changeResponse(index)"
          @open-click="changeResponse(index, true)"
          @update-status="(status) => response.status = status"
          @change-view-status="changeStatus(response.status === 1 ? 2 : 1, index, false)"
          @call="call(index)"
          @edit="openEditColloquy(index)"
        />
      </div>
      <PaginationComponent
        :per-page="perPage"
        :items-count="itemsCount"
        @change-page="changePage"
      />
    </div>
    <div
      class="response-view d-md-block col-md-4 position-relative"
      :class="activeResponse === null ? null : 'response-view_active'"
    >
      <div class="response-view-wrap">
        <ClientOnly>
          <Teleport
            to="#ItemInfoModal"
            :disabled="isDesktop"
          >
            <div
              class="h-100 bg-white shadow-2 rounded-lg-20 overflow-hidden"
              :style="{ height: wrapHeight === undefined ? undefined : wrapHeight + 'px !important' }"
            >
              <div
                v-show="answersLoading"
                class="w-100 h-100"
              >
                <AppLoader is-height />
              </div>
              <div
                v-if="!openInviteWork"
                class="position-relative flex-column align-items-center rounded-md-20 overflow-hidden gap-24 h-100"
                :class="(activeResponse === null || openInviteColloquies) && !answersLoading ? 'd-none' : 'd-flex'"
              >
                <VideoPlayer
                  v-show="activeResponse !== null && (activeTab !== 1 || answers.length !== 0)"
                  :is-response="true"
                  :is-archive="activeTab === 3"
                  :answers="getVideoData"
                  :summary="activeResponse === null ? undefined : responses[activeResponse]?.summary"
                  :chat-id="activeResponse === null || responses[activeResponse].chat === null ? undefined : responses[activeResponse].chat.id"
                  :default-tab="activeTab === 1 ? PlayerTabs.AdditionVideos : PlayerTabs.Videos"
                  :wrap-height="wrapHeight"
                  :wrap-width="wrapWidth"
                  @close="activeResponse = null"
                >
                  <template #actionBar>
                    <div v-show="activeTab !== 3 && activeResponse !== null && !responses[activeResponse].isChangeStatus">
                      <div
                        v-if="activeTab !== 0"
                        v-click-outside="closeDropdownReturnStatus"
                        class="dropdown d-inline-block me-5"
                      >
                        <AppButton
                          title="Переместить"
                          class="dropdownReturnStatus"
                          :variant="BtnVariant.Tertiary"
                          is-icon
                          @click="dropdownReturnStatus = !dropdownReturnStatus"
                        >
                          <svg class="h-100 w-100">
                            <use xlink:href="/public/icons/auth-sprite.svg#move-down" />
                          </svg>
                        </AppButton>
                        <ul
                          class="dropdown-menu position-absolute bottom-100 start-0 mb-5"
                          :class="dropdownReturnStatus ? 'show' : ''"
                        >
                          <li>
                            <button
                              class="dropdown-item dropdownReturnStatus"
                              @click="changeStatus(2); dropdownReturnStatus = false;"
                            >
                              В отклики
                            </button>
                          </li>
                        </ul>
                      </div>
                      <AppButton
                        class="btn-book"
                        title="Отказать"
                        is-icon
                        variant
                        @click="changeStatus(3)"
                      >
                        <svg class="h-100 w-100">
                          <use xlink:href="/public/icons/main.svg#close" />
                        </svg>
                      </AppButton>
                      <div
                        v-click-outside="closeDropdown"
                        class="dropdown d-inline-block ms-5"
                      >
                        <AppButton
                          class="dropdownInvite rounded-10"
                          @click="dropdownInvite = !dropdownInvite"
                        >
                          Пригласить...
                        </AppButton>
                        <ul
                          class="dropdown-menu position-absolute bottom-100 end-0 mb-5"
                          :class="dropdownInvite ? 'show' : ''"
                        >
                          <li v-if="activeTab === 0">
                            <button
                              class="dropdownInvite dropdown-item"
                              @click="changeStatus(4); dropdownInvite = false;"
                            >
                              на доп. вопросы
                            </button>
                          </li>
                          <li v-if="activeTab < 2">
                            <button
                              class="dropdownInvite dropdown-item"
                              @click="openInviteColloquies = true; isEditColloquies = false; dropdownInvite = false;"
                            >
                              на собеседование
                            </button>
                          </li>
                          <li>
                            <button
                              class="dropdownInvite dropdown-item"
                              @click="openInviteWork = true; dropdownInvite = false"
                            >
                              на работу
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </template>
                </VideoPlayer>
              </div>
              <InviteToInterview
                class="p-10"
                :class="activeResponse === null || !openInviteColloquies ? 'd-none' : ''"
                :vacancy-id="vacancyId"
                :response-id="getResponseId"
                :active-response="responses[activeResponse]"
                :is-editing="isEditColloquies"
                :is-work="openInviteWork"
                @close="openInviteColloquies = false; openInviteWork = false"
                @invited="(val) => invited(val)"
                @saved="(val) => saved(val)"
              />
              <form
                class="p-10 flex-column"
                :class="openInviteWork ? 'd-flex' : 'd-none'"
                autocomplete="off"
                @submit.prevent="onSubmitInviteJob"
              >
                <AppButton
                  class="mb-24"
                  type="button"
                  is-icon
                  @click="openInviteWork = false"
                >
                  <svg class="h-100 w-100">
                    <use xlink:href="/public/icons/main.svg#close" />
                  </svg>
                </AppButton>
                <AppTextArea
                  v-model="formInviteDescription"
                  class="mb-10"
                  label="Комментарий для соискателя"
                  :note="String(formInviteDescription?.length??0) + '/200'"
                  error-message="Текст не может содержать более 200 символов"
                  :is-invalid="!isInviteDescriptionValid"
                />
                <AppButton
                  type="submit"
                  class="mx-auto"
                  :disabled="formInviteLoaded"
                >
                  Принять
                </AppButton>
              </form>
              <div
                class="p-10 text-center fw-bold"
                :class="activeResponse !== null ? 'd-none' : null"
              >
                Выберите отклик для просмотра
              </div>
            </div>
          </Teleport>
        </ClientOnly>
      </div>
    </div>

    <VueFinalModal
      v-model="itemInfoModalShow"
      display-directive="show"
    >
      <div id="ItemInfoModal" />
    </VueFinalModal>
  </div>
</template>

<script setup lang="ts">
import type {Answer} from "~/entities/answer";
import type {VideoData} from "~/entities/videoData";
import type {Response} from "~/entities/response";
import ResponseCard from "~/components/responseCard.vue";
import InviteToInterview from "~/components/company/InviteToInterview.vue";
import EditColloquyModal from "~/components/modal/EditColloquyModal.vue";
import SelectLayout from "~/components/inputs/selectLayout.vue";
import {BtnVariant} from "~/composables/enums/BtnVariant";
import AppTextArea from "~/components/inputs/AppTextArea.vue";
import {useModal, VueFinalModal} from "vue-final-modal";
import {useCheckMobile} from "~/composables/CheckMobile";
import {PlayerTabs} from "~/composables/enums/PlayerTabs";
import type {VideoPlayerAnswers} from "~/entities/VideoPlayerAnswers";
import {useClient} from "~/stores/client";

const { $toast } = useNuxtApp();
const client = useClient();
const route = useRoute();
const router = useRouter();

type Colloquy = {
  id: string,
  client: Client
};

const props = defineProps({
  isPreview: { type: Boolean },
  activeTab: { type: Number, required: true },
  vacancyId: { type: String, required: true }
});

//-----VARIABLES-----\\
const perPage = 10;
let viewStatusTimer: Timeout;
let currentDate = new Date();
currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

//-----STATE-----\\
const calledClient = useState<Colloquy|null>('calledClient', () => null);
const responses = ref<Response[]>([]);
const answers = ref<Answer[]>([]);
const additionAnswers = ref<Answer[]>([]);//TODO: tmp!!!
const answersLoading = ref<boolean>(false);
const activeResponse = ref<number|null>(null);
const activeDate = ref<Date>();
const dropdownInvite = ref<boolean>(false);
const dropdownReturnStatus = ref<boolean>(false);
const isEditColloquies = ref<boolean>(false);
const isSaved = ref<boolean>(false);
const isUngroupColloquies = ref<boolean>(false);
const activeIndex = ref<number|null>(null);
const openInviteWork = ref<boolean>(false);
const openInviteColloquies = ref<boolean>(false);
const busyDays = ref<Date[]>([]);
const page = ref<number>(route.query.page??1);
const sorting = ref<string>(props.activeTab === 2 ? route.query.sorting??'start_time:ASC' : route.query.sorting??'created:DESC');
const isPastColloquies = ref<boolean>(false);
const itemsCount = ref<number>(0);
const isDesktop = ref(false);
const itemInfoModalShow = ref(false);
const itemInfoInner = ref<HTMLDivElement>();
const wrapWidth = ref<number|undefined>(undefined);
const wrapHeight = ref<number|undefined>(undefined);
const loading = ref<boolean>(false);
//===invite to job===\\
const formInviteDescription = ref<string>();
const formInviteLoaded = ref<boolean>(false);

//-----FETCH-----\\
if (!props.isPreview) {
  await getResponses();
}

//-----COMPUTED-----\\
const useEditColloquyModal = useModal({
  component: EditColloquyModal,
  attrs: {
    onConfirm() {
      useEditColloquyModal.close();
    },
    onSaved(data: any) {
      saved(data);
      useEditColloquyModal.close();
    }
  }
});
const getVideoData = computed(() => {
  if (activeResponse.value === null) {
    return undefined;
  }

  if (props.activeTab === 2) {
    return responses.value[activeResponse.value].summary.questions.map((question) => {
      return {
        src: question.answer === null ? null : question.answer.src,
        question: question.question,
        isAssembled: question.answer === null ? false : question.answer.isAssembled,
        duration: question.answer === null ? 0 : question.answer.duration,
      } as VideoData;
    });
  }

  const response: VideoPlayerAnswers = {
    default: [],
    additions: undefined,
  };
  response.default = answers.value.map((answer: Answer) => {
    return {
      src: answer.src,
      question: answer.question.question,
      isAssembled: answer.isAssembled,
      duration: answer.duration,
    } as VideoData;
  });
  if (props.activeTab !== 0) {
    response.additions = additionAnswers.value.map((answer: Answer) => {
      return {
        src: answer.src,
        question: answer.question.question,
        isAssembled: answer.isAssembled,
        duration: answer.duration,
      } as VideoData;
    });
  }
  return response;
});
const getResponseId = computed(() => activeResponse.value === null ? null : responses.value[activeResponse.value].id);
const getResponseItems = computed(() => {
  if (props.activeTab !== 2 || isUngroupColloquies.value) {
    return responses.value;
  }

  if (activeDate.value === undefined) {
    return [];
  } else if (isSaved.value) {
    return responses.value;
  }
  return responses.value.filter(val => val.colloquy.startDate.getTime() === activeDate.value.getTime());
});
const isInviteDescriptionValid = computed<boolean>(() => {
  return formInviteDescription.value === undefined || formInviteDescription.value?.length <= 200;
});

const unwatchActiveVacancy = watch(() => activeResponse.value, () => {
  itemInfoModalShow.value = activeResponse.value !== null;
});

//-----METHODS-----\\
function openEditColloquy(index: number) {
  useEditColloquyModal.open();
  useEditColloquyModal.options.attrs.vacancyId = props.vacancyId;
  useEditColloquyModal.options.attrs.response = responses.value[index];
}
function sortResponses(){
  changeGetParams();
  getResponses(1,activeDate.value, isPastColloquies.value, null, isUngroupColloquies.value);
}

function changeGetParams() {
  const params = [];
  for (const key in route.query) {
    if (key === 'page'){
      params.push(key + '=1');
    } else if(key !== 'sorting') {
      params.push(key + '=' + route.query[key]);
    }
  }

  params.push('sorting=' + sorting.value);
  router.push(route.path + '?' + params.join('&'));
}

function changeTab(params: any) {
  changeGetParams();
  getResponses(1,null,params.isPast, null, !params.isGroup);
}

function changeDate(param: any) {
  activeResponse.value = null;
  openInviteColloquies.value = false;
  activeDate.value = param.busyDate;

  if (param.busyDate === undefined) {
    activeIndex.value = param.activeIndex;
  }

  const params = [];
  for (const key in route.query) {
    if(key !== 'sorting' && key !== 'page') {
      params.push(key + '=' + route.query[key]);
    }
  }

  params.push('sorting=' + sorting.value);
  params.push('page=1');
  router.push(route.path + '?' + params.join('&'));

  getResponses(1, param.busyDate, isPastColloquies.value);
}
function ungroupingColloquies(isGroup: boolean) {
  changeGetParams();
  getResponses(1, null, isPastColloquies.value, null,!isGroup);
}

function isOnline(index: number|null): boolean {
  if (index === null) {
    return false;
  }

  return responses.value[index].status === 5;
}

function getStatus(index: number): string|undefined {
  const status = responses.value[index].status;

  if (responses.value[index].isChangeStatus) {
    if (status === 2) {
      return 'Перемещен в отклики';
    } else if (status === 4) {
      return 'Приглашен на интервью';
    } else if (status === 7) {
      return 'Приглашен на работу';
    } else if (status === 3) {
      return 'Перенесен в архив';
    } else if (status === 5 || status === 6) {
      return 'Приглашен на собеседование';
    }
  }

  if (status === 1) {
    return 'Новый';
  } else if (status === 2) {
    return 'Просмотрен';
  } else if (status === 3) {
    return 'Отказ';
  } else if (status === 4) {
    return responses.value[index].answered ? 'Записано' : 'Ожидание записи';
  } else if (status === 5) {
    return 'Позвонить через sobes';
  } else if (status === 6) {
    return 'Ожидание кандидата';
  } else if (status === 7) {
    return 'Принят на работу';
  } else if (status === 8) {
    return 'Отменен';
  }

  if (responses.value[index].status === 3) {
    return 'Отказ';
  } else if (responses.value[index].status === 7) {
    return 'Принят';
  }
}
async function getResponses(newPage: number|null = null, date: Date|null = null, isPast: boolean = false, newDate: Date|null = null, isUngroup: boolean = false) {
  loading.value = true;
  if (props.isPreview) {
    return;
  }

  if (newPage !== null) {
    page.value = newPage;
  }
  isUngroupColloquies.value = isUngroup;
  isPastColloquies.value = isPast;

  if (props.activeTab === 2) {
    let tmpDate;
    if (newDate !== null && !isPast) {
      const month = newDate.getMonth() + 1;
      tmpDate = newDate.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + newDate.getDate();
    } else if (date === null) {
      tmpDate = new Date();
      if (isPastColloquies.value) {
        tmpDate.setDate(tmpDate.getDate() - 14);
      }
      const month = tmpDate.getMonth() + 1;
      tmpDate = tmpDate.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (tmpDate.getDate() < 10 ? '0' + tmpDate.getDate(): tmpDate.getDate());
    } else {
      const month = date.getMonth() + 1;
      tmpDate = date.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + date.getDate();
    }
    const { data } = await mainFetch(`/vacancies/${props.vacancyId}/colloquies`, {
      query: {
        perPage,
        dateFrom: date === null ? tmpDate : undefined,
        date: date !== null ? tmpDate : undefined,
        dateNew: newDate === null ? undefined : tmpDate,
        page: page.value,
        sort: sorting.value,
        isPast,
        isUngroup
      }
    });//TODO: проверка

    responses.value = data.value.responses.items.map(val => {
      const date = new Date(val.colloquy.startTime * 1000);
      val.colloquy['startDate'] = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      val['startDateTime'] = date.toLocaleTimeString(['ru-RU', 'en-US'], {hour: '2-digit', minute: '2-digit'});
      return val;
    });

    itemsCount.value = data.value.responses.count;
    if (date === null || newDate !== null) {
      busyDays.value = data.value.responses.dates;
    }

    if (activeDate.value === undefined && !isUngroup) {
      itemsCount.value = 0;
    }
    loading.value = false;
    return;
  }

  const { data } = await mainFetch('responses', {
    query: {
      perPage,
      page: page.value,
      sort: sorting.value,
      vacancy: props.vacancyId,
      is_invited: props.activeTab === 1 ? true : undefined,
      is_archived: props.activeTab === 3 ? true : undefined,
    }
  });

  responses.value = data.value.responses.items;

  responses.value.map(val => {
    val['isChangeStatus'] = false;
    return val;
  });

  itemsCount.value = data.value.responses.count;
  loading.value = false;
}
async function changeResponse(index: number, isOpenBtn: boolean = false) {
  if (activeResponse.value === index || (!isDesktop.value && !isOpenBtn)) {
    return;
  }

  answers.value = [];
  additionAnswers.value = [];
  activeResponse.value = index;
  openInviteColloquies.value = false;

  if (props.activeTab === 2) {
    return;
  }

  answersLoading.value = true;
  if (props.activeTab === 1) {
    const { data } = await mainFetch(`responses/${responses.value[index].id}/answers`);
    answers.value = data.value.answers;//TODO: проверка
    additionAnswers.value = data.value.additionAnswers;
    answersLoading.value = false;
    return;
  }

  clearTimeout(viewStatusTimer);

  // const { data } = await mainFetch(`clients/${responses.value[index].summary.client.id}/answers`);
  const { data } = await mainFetch(`summaries/${responses.value[index].summary.id}/answers-to-questions`);
  answers.value = data.value.answers;//TODO: проверка

  answersLoading.value = false;

  if (props.activeTab !== 0) {
    return;
  }
  viewStatusTimer = setTimeout((index: number) => {
    if (index === activeResponse.value) {
      changeStatus(2, index, false);
    }
  }, 700, activeResponse.value);
}
async function changeStatus(status: number, itemIndex: number|null = null, remove: boolean = true, message: string|null = null) {
  const index = itemIndex??activeResponse.value!;
  if (props.activeTab === 0 && responses.value[index].status !== 1 && responses.value[index].status !== 2) {
    return;
  }

  const responseId = responses.value[index].id;
  await mainFetch('responses/' + responseId, {
    method: 'PUT',
    body: { status, message }
  }, true).then(response => {
    response = response.data.value??response.error.value?.data;
    if (response.error.code !== 200) {
      $toast.error(response.error.clientMessage === null ? 'Ошибка' : response.error.clientMessage);
      return;
    }

    if (remove) {
      activeResponse.value = null;
      responses.value[index].isChangeStatus = true;
    }
    if (message !== null) {
      openInviteWork.value = false;
      formInviteDescription.value = undefined;
    }

    if (response.chat !== undefined) {
      responses.value[index].chat = {
        id: response.chat.id
      };
    }

    responses.value[index].status = status;
  });

  if (message !== null) {
    formInviteLoaded.value = false;
  }
}
function invited(params: any) {
  let index: number;
  if (params.responseId === responses.value[activeResponse.value].id) {
    index = activeResponse.value!;
  } else {
    index = responses.value.findIndex((item: Response) => item.id === params.responseId);
  }
  activeResponse.value = null;
  responses.value[index].isChangeStatus = true;
  responses.value[index].status = params.platform === 0 ? 5 : 6;
}
function saved(params: any) {
  changeGetParams();

  if (params.isChangeDate) {
    if (!isPastColloquies.value) {
      activeDate.value = params.newDate;
    }

    activeIndex.value = Math.round(Math.abs(activeDate.value.getTime() - currentDate.getTime()) / 86400000) + (isPastColloquies.value ? 0 : 1);
    getResponses(1, activeDate.value, isPastColloquies.value, params.newDate, isUngroupColloquies.value);
  } else {
    getResponses(1, null, isPastColloquies.value, null, isUngroupColloquies.value);
  }

  activeResponse.value = null;
  openInviteColloquies.value = false;
  $toast.success('Данные о собеседовании изменены');
}
function call(index: number) {

  //TODO: проверка, идет ли уже звонок
  if (activeResponse.value === null) {
    //return;
  }
  calledClient.value = {
    id: responses.value[activeResponse.value??index].colloquy.id,
    client: responses.value[activeResponse.value??index].summary.client
  };
}
function changePage(page: number) {
  activeResponse.value = null;
  getResponses(page,isUngroupColloquies.value ? null : activeDate.value, isPastColloquies.value, null, isUngroupColloquies.value);
}
function closeDropdown(el) {
  if (dropdownInvite.value === false) {
    return;
  }

  const tmp = document.querySelectorAll('.dropdownInvite');
  for (let i = 0; i < tmp.length; i++) {
    if (tmp[i].contains(el.target)) {
      return;
    }
  }

  dropdownInvite.value = false;
}
function closeDropdownReturnStatus(el) {
  if (dropdownReturnStatus.value === false) {
    return;
  }

  const tmp = document.querySelectorAll('.dropdownReturnStatus');
  for (let i = 0; i < tmp.length; i++) {
    if (tmp[i].contains(el.target)) {
      return;
    }
  }

  dropdownReturnStatus.value = false;
}
function onSubmitInviteJob() {
  if (formInviteLoaded.value || !isInviteDescriptionValid.value) {
    return;
  }

  formInviteLoaded.value = true;
  changeStatus(7, null, true, formInviteDescription.value??'');
}
function onResize() {
  if (itemInfoModalShow.value === true) {
    wrapHeight.value = window.innerHeight;
  }
}

onBeforeMount(() => {
  isDesktop.value = !useCheckMobile();
  if (isDesktop.value) {
    unwatchActiveVacancy();
  } else {
    wrapHeight.value = window.innerHeight;
    wrapWidth.value = window.innerWidth;
    window.addEventListener("resize", onResize);
  }
});
onMounted(() => {
  if (isDesktop.value) {
    wrapWidth.value = itemInfoInner.value?.offsetWidth;
    wrapHeight.value = itemInfoInner.value?.offsetHeight;
  }
});
onUnmounted(() => {
  window.removeEventListener("resize", onResize);
});
</script>

<style scoped lang="scss">
//@import 'vue-toastification/src/scss/index';

@media (min-width: 992px) {
  .response-view > div {
    position: sticky;
    height: calc(100vh - 30px) !important;
    top: 15px;
  }
}
</style>
